import React from "react";
import Logo from "../assets/logo.png";
const Reconstruction = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="mb-8">
        <img src={Logo} alt="Logo" className=" h-24 object-contain" />
      </div>
      <p className="text-lg text-gray-600 mb-4 italic text-center">
        You learn more from losing than winning.
      </p>
      <p className="text-2xl font-bold text-gray-800 text-center">
        {/* მიმდინარეობს საიტის განახლება */}
        Site update in progress
      </p>
    </div>
  );
};

export default Reconstruction;
